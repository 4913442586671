<template>
  <div>
    <data-detail />
  </div>
</template>
<script>
import dataDetail from "@/components/daily/searchdata/table.vue";
export default {
  components: { dataDetail },
  data() {
    return {};
  },

  mounted() {},
  methods: {},
};
</script>
<style>
.cell > .el-button--mini {
  margin: 0 3px !important;
}
</style>