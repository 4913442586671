<template>
  <div>
    <el-card class="box-card mb10">
      <el-radio-group v-model="form.week" @change="change">
        <el-radio-button
          v-for="item in dateRange"
          :label="item.value"
          :key="item.value"
          >{{ item.label }}
        </el-radio-button>
      </el-radio-group>
      <el-date-picker
        style="margin-left: 10px"
        v-model="form.date"
        size="large"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      >
      </el-date-picker>
      <el-input
        style="width: 20%; margin-left: 10px"
        v-model="form.value"
        placeholder="春城、旺庄、新区"
      ></el-input>
      <el-button style="margin-left: 10px">查询</el-button>
    </el-card>
    <el-card class="box-card">
      <!-- 搜索与添加区域 -->
      <el-table
        header-align="center"
        :data="tableData"
        style="width: 100%"
        :border="true"
        max-height="600px"
        size="mini"
      >
        <el-table-column prop="name" label="微信名"></el-table-column>

        <el-table-column type="type" label="预警类型">
          <template slot-scope="scope">
            <span>{{ scope.row.type }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="value" label="预警阈值"></el-table-column>
        <el-table-column prop="id" label="姓名 "> </el-table-column>
        <el-table-column prop="startDate" label="开始时间"> </el-table-column>
        <el-table-column prop="endDate" label="结束时间"> </el-table-column>
        <el-table-column prop="role_name" label="是否启用">
          <template slot-scope="scope">
            <span>{{ scope.row.status == 1 ? "是" : "否" }}</span>
          </template>
        </el-table-column>
        <!--        <el-table-column prop="" label="操作" width="180">-->
        <!--          <template slot-scope="scope">-->
        <!--            <el-tooltip-->
        <!--              class="item"-->
        <!--              effect="dark"-->
        <!--              content="编辑"-->
        <!--              :enterable="false"-->
        <!--              placement="top-end"-->
        <!--            >-->
        <!--              <el-button-->
        <!--                type="primary"-->
        <!--                icon="el-icon-edit"-->
        <!--                size="mini"-->
        <!--                @click="editWaringPage(true, scope.row, '编辑')"-->
        <!--              >-->
        <!--              </el-button>-->
        <!--            </el-tooltip>-->
        <!--            <el-tooltip-->
        <!--              class="item"-->
        <!--              effect="dark"-->
        <!--              content="删除"-->
        <!--              placement="top-end"-->
        <!--              :enterable="false"-->
        <!--            >-->
        <!--              <el-popconfirm-->
        <!--                confirm-button-text="是的"-->
        <!--                cancel-button-text="先不删"-->
        <!--                icon="el-icon-info"-->
        <!--                icon-color="Gold"-->
        <!--                title="是否将该预警信息删除"-->
        <!--                @confirm="deleteuser(scope.row)"-->
        <!--              >-->
        <!--                <el-button-->
        <!--                  type="danger"-->
        <!--                  icon="el-icon-delete"-->
        <!--                  size="mini"-->
        <!--                  slot="reference"-->
        <!--                >-->
        <!--                </el-button>-->
        <!--              </el-popconfirm>-->
        <!--            </el-tooltip>-->
        <!--            <el-tooltip-->
        <!--              class="item"-->
        <!--              effect="dark"-->
        <!--              content="复制"-->
        <!--              placement="top-end"-->
        <!--              :enterable="false"-->
        <!--            >-->
        <!--              <el-button-->
        <!--                type="warning"-->
        <!--                icon="el-icon-document-copy"-->
        <!--                size="mini"-->
        <!--                @click="editWaringPage(true, scope.row, '复制')"-->
        <!--              ></el-button>-->
        <!--            </el-tooltip>-->
        <!--          </template>-->
        <!--        </el-table-column>-->
      </el-table>
      <!-- 分页区域 -->
      <!-- <span class="demonstration">完整功能</span> -->
    </el-card>
  </div>
</template>
<script>
// import Dialog from "./dialog.vue";
const dateRange = [
  { label: "近一周", value: "week" },
  { label: "近1个月", value: "1moonth" },
  { label: "近3个月", value: "2moonth" },
  { label: "近6个月", value: "3moonth" },
];

import { getWarning, deleWarningPage } from "@/utils/masterdata.js";
export default {
  components: {},
  data() {
    return {
      form: {
        week: "week",
      },
      dateRange,
      id: "",
      centerDialogVisible: false,
      inputstates: false,
      addobtntate: "添加",
      ruleForm: {},

      // 获取用户列表的对象
      queryinfo: {
        query: "",
        // 当前的页数
      },
      userList: [],
      total: 0,
      tableData: [],
    };
  },

  mounted() {
    this.getWarning();
  },
  methods: {
    change(e) {
      console.log(e);
    },
    deleteuser(item) {
      console.log(item);
      deleWarningPage({ ...item }).then((res) => {
        this.$message.success("删除成功");
        this.getWarning();
      });
    },
    async getWarning() {
      const { body } = await getWarning({ ...this.ruleForm });
      this.tableData = body.map((e) => {
        return {
          account: e.account,
          createTime: e.createTime,
          endDate: e.endDateStr,
          id: e.id,
          name: e.name,
          startDate: e.startDateStr,
          status: e.status,
          type: e.type,
          unit: e.unit,
          updateTime: e.updateTime,
          value: e.value,
        };
      });
    },
    add() {
      this.$refs.addRow.setVisible(true, null, "新增");
    },
    editWaringPage(visible, row, title) {
      this.$refs.addRow.setVisible(visible, row, title);
    },

    // 监听pagesize的改变
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.queryinfo.pagesize = val;
      this.getUserList();
    },
    // 监听页码值改变的事件
    handleCurrentChange(val) {
      this.queryinfo.pagenum = val;
      console.log(`当前页: ${val}`);
    },
  },
};
</script>
<style>
.cell > .el-button--mini {
  margin: 0 3px !important;
}
.el-date-editor .el-range-separator {
  padding: 0;
}
</style>
